import React, { createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [isLoggedIn, saveIsLoggedIn] = useState(false);
    const [token, saveToken] = useState('');
    const [userId, saveUserId] = useState(0);
    const [roleId, saveRoleId] = useState(0);
    const [userName, saveUserName] = useState('');

    const value = {
        isLoggedIn,
        saveIsLoggedIn,
        token,
        saveToken,
        roleId,
        saveRoleId,
        userId,
        saveUserId,
        userName,
        saveUserName,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider };