import React from 'react';
import { Navbar } from 'react-bootstrap';

const TopBar = () => {
    return (
        <Navbar bg='light'>
            <Navbar.Brand href="#">Eventikk CRM</Navbar.Brand>
        </Navbar>
    );
}

export default TopBar;