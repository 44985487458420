import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import $ from 'jquery';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Buffer } from 'buffer';

const Categories = () => {
    const defaultObj = {
        'id': 0,
        'name': '',
        'name_en': '',
        'localPath': '',
        'path': '',
    };
    const [tableData, saveTableData] = useState([]);
    const [showModal, saveShowModal] = useState(false);
    const [currentModel, saveCurrentModel] = useState(defaultObj);
    const { id, name, name_en, path } = currentModel;
    const [formErrors, saveFormErrors] = useState({});
    const [image, saveImage] = useState(null);

    useEffect(() => {
        getTableData();
    }, []);

    const getTableData = async () => {
        const res = await axios.get('/categories');
        if (res && res.data && res.data.status) {
            saveTableData(res.data.data);
        }
    }

    const showDeleteAlert = (currentId, dataName) => {
        $.confirm({
            title: `¿Está seguro que desea eliminar el dato ${dataName}?`,
            content: '',
            type: 'red',
            buttons: {
                accept: {
                    text: 'ELIMINAR',
                    btnClass: 'btn-danger',
                    keys: [],
                    action: function () {
                        const deleteModel = () => {
                            axios.post(`/categories/${currentId}`, { _method: 'DELETE' })
                                .then(res => {
                                    if (res && res.data && res.data.status) {
                                        getTableData();
                                    } else {
                                        $.alert({
                                            title: 'Alerta',
                                            content: 'Ha ocurrido un error ejecutando el borrado.',
                                            type: 'red',
                                        });
                                    }
                                    saveShowModal(false);
                                })
                        }
                        deleteModel()
                    }
                },
                cancel: {
                    text: 'CANCELAR',
                    btnClass: 'btn-default',
                    keys: [],
                    action: function () {

                    }
                }
            }
        });
    }

    const showForm = async (currentId) => {
        saveFormErrors({})
        if (currentId === 0) {
            saveCurrentModel(defaultObj);
            saveImage(null);
        } else {
            const getModel = async () => {
                const res = await axios.get(`/categories/${currentId}`);

                if (res && res.data && res.data.status) {
                    saveCurrentModel(res.data.data);
                    getImage(res.data.data.id);
                }

            }
            await getModel();
        }
        saveShowModal(true);
    }

    const getImage = async (id) => {
        if (id !== null && id !== '') {
            const res = await axios.get(`/categories/showImage/${id}`, { responseType: 'arraybuffer' });
            saveImage(Buffer.from(res.data, 'binary').toString('base64'));
        } else {
            saveImage(null);
        }
    }

    const optionsFormatter = (cell, row, rowIndex, { showForm, showDeleteAlert }) => {
        return (
            <div className="btn-group" role="group" aria-label="Options">
                <Button variant='primary' className="text-white" onClick={() => { showForm(row.id); }}><i className="bi bi-pencil-square"></i></Button>
                <Button variant='danger' onClick={() => { showDeleteAlert(row.id, row.name); }}><i className="bi bi-x-circle"></i></Button>
            </div >
        )
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            headerClasses: 'align-top header-id',
        },
        {
            dataField: 'name',
            text: 'Nombre',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: '',
            text: 'Opciones',
            formatter: optionsFormatter,
            formatExtraData: {
                showForm,
                showDeleteAlert,
            },
            headerClasses: 'align-top',
        }
    ];

    const updateModelWithFormData = (e, fieldName) => {
        let name, value;

        if (fieldName !== undefined && fieldName !== '') {
            name = fieldName;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        saveCurrentModel({
            ...currentModel,
            [name]: value
        });
    }

    const handleValidation = () => {
        let errors = {};
        let isFormValid = true;

        if (name === '' || name.length > 100) {
            errors["name"] = 'El campo nombre está vacío o supera los 50 caracteres.';
            isFormValid = false;
        }

        if (name_en === '' || name_en.length > 100) {
            errors["name_en"] = 'El campo nombre está vacío o supera los 50 caracteres.';
            isFormValid = false;
        }

        saveFormErrors(errors);
        return isFormValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation()) {
            const _currentModel = { ...currentModel };
            const file = e.target[2].files[0];
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const fileData = new FormData();
            fileData.append('file', file);
            Object.keys(_currentModel).map(k => {
                fileData.append(k, _currentModel[k]);
            });
            fileData.append('_method', (currentModel.id === 0 ? 'POST' : 'PUT'));
            const res = await axios.post(`/categories${(currentModel.id != 0 ? '/' + currentModel.id : '')}`, fileData, config);
            if (res && res.data && res.data.status) {
                getTableData();
                saveShowModal(false);
            } else {
                $.alert({
                    title: 'Error',
                    content: 'Ha ocurrido un error al guardar los datos en el servidor.',
                    type: 'red',
                });
            }
        }
    };

    return (
        <>
            <h1>Categorías</h1>
            <Button variant='success' className='mb-3' onClick={() => { showForm(0); }}><i className="bi bi-plus-circle"></i></Button>
            <BootstrapTable
                bootstrap4={true}
                columns={columns}
                data={tableData}
                keyField='id'
                filter={filterFactory()}
            />

            <Modal show={showModal} onHide={() => { saveShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${id === 0 ? 'Añadir' : 'Editar'}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id='modelForm' onSubmit={handleSubmit}>
                        <Form.Group controlId='name-control'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" value={name} name='name' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('name')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='name_en-control'>
                            <Form.Label>Nombre (Inglés)</Form.Label>
                            <Form.Control type="text" value={name_en} name='name_en' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('name_en')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.name_en}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="file-control" className="mb-3">
                            <Form.Label>Imagen</Form.Label>
                            <Form.Control
                                type="file"
                                accept='image/png, image/gif, image/jpeg'
                                name='localPath'
                                onChange={updateModelWithFormData}
                            />
                            <Form.Text>
                                Si vuelve a subir una imagen, se remplazará la anterior.
                            </Form.Text>
                        </Form.Group>
                        <img src={`data:image/png;base64, ${image}`} className='w-100' />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { saveShowModal(false); }}>Cerrar</Button>
                    <Button variant="primary" type='submit' form='modelForm'>Guardar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Categories;