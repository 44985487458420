import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import $ from 'jquery';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';

const Notifications = () => {
    const defaultObj = {
        'id': 0,
        'subject': '',
        'description': '',
        'notification_type': 0,
        'user_id': 0,
    };
    const [tableData, saveTableData] = useState([]);
    const [users, saveUsers] = useState([]);
    const [showModal, saveShowModal] = useState(false);
    const [currentModel, saveCurrentModel] = useState(defaultObj);
    const { id, subject, description, notification_type, user_id } = currentModel;
    const [formErrors, saveFormErrors] = useState({});
    const [image, saveImage] = useState(null);

    useEffect(() => {
        getTableData();
        getUsers();
    }, []);

    const getTableData = async () => {
        const res = await axios.get('/notifications');
        if (res && res.data && res.data.status) {
            saveTableData(res.data.data);
        }
    }

    const getUsers = async () => {
        const res = await axios.get('/users');
        if (res && res.data && res.data.status) {
            let dataSelect = [];
            res.data.data.map(dat => {
                dataSelect.push({
                    value: dat.id,
                    label: dat.name
                });
            })
            saveUsers(dataSelect);
        }
    }

    const showDeleteAlert = (currentId, dataName) => {
        $.confirm({
            title: `¿Está seguro que desea eliminar el dato ${dataName}?`,
            content: '',
            type: 'red',
            buttons: {
                accept: {
                    text: 'ELIMINAR',
                    btnClass: 'btn-danger',
                    keys: [],
                    action: function () {
                        const deleteModel = () => {
                            axios.post(`/notifications/${currentId}`, { _method: 'DELETE' })
                                .then(res => {
                                    if (res && res.data && res.data.status) {
                                        getTableData();
                                    } else {
                                        $.alert({
                                            title: 'Alerta',
                                            content: 'Ha ocurrido un error ejecutando el borrado.',
                                            type: 'red',
                                        });
                                    }
                                    saveShowModal(false);
                                })
                        }
                        deleteModel()
                    }
                },
                cancel: {
                    text: 'CANCELAR',
                    btnClass: 'btn-default',
                    keys: [],
                    action: function () {

                    }
                }
            }
        });
    }

    const showForm = async (currentId) => {
        saveFormErrors({})
        if (currentId === 0) {
            saveCurrentModel(defaultObj);
            saveImage(null);
        } else {
            const getModel = async () => {
                const res = await axios.get(`/notifications/${currentId}`);

                if (res && res.data && res.data.status) {
                    saveCurrentModel(res.data.data);
                }

            }
            await getModel();
        }
        saveShowModal(true);
    }

    const launchNotification = async (notificationId) => {
        $.confirm({
            title: `¿Seguro que desea enviar este registro?`,
            content: '',
            type: 'green',
            buttons: {
                accept: {
                    text: 'ENVIAR',
                    btnClass: 'btn-success',
                    keys: [],
                    action: async function () {
                        const res = await axios.get(`/notifications/launchNotification/${notificationId}`);
                        if (res && res.data && res.data.status) {
                            getTableData();
                        } else {
                            $.alert({
                                title: 'Alerta',
                                content: 'Ha ocurrido un error enviando las notificaciones.',
                                type: 'red',
                            });
                        }
                    }
                },
                cancel: {
                    text: 'CANCELAR',
                    btnClass: 'btn-default',
                    keys: [],
                    action: function () {

                    }
                }
            }
        });
    }

    const optionsFormatter = (cell, row, rowIndex, { showForm, showDeleteAlert, launchNotification }) => {
        return (
            <div className="btn-group" role="group" aria-label="Options">
                <Button variant='primary' className="text-white" onClick={() => { launchNotification(row.id); }} disabled={row.is_sent === true}><i className="bi bi-send"></i></Button>
                <Button variant='primary' className="text-white" onClick={() => { showForm(row.id); }} disabled={row.is_sent === true}><i className="bi bi-pencil-square"></i></Button>
                <Button variant='danger' onClick={() => { showDeleteAlert(row.id, row.name); }}><i className="bi bi-x-circle"></i></Button>
            </div >
        )
    }

    const notificationTypeFormatter = (cell, row) => {
        return (
            <div>{row.notification_type === 1 ? 'Todos' : 'Usuario'}</div>
        );
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            headerClasses: 'align-top header-id',
        },
        {
            dataField: 'subject',
            text: 'Título',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: 'description',
            text: 'Descripción',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: 'notification_type',
            text: 'Tipo',
            headerClasses: 'align-top',
            filter: textFilter(),
            formatter: notificationTypeFormatter,
        },
        {
            dataField: 'user_name',
            text: 'Usuario',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: 'created_at',
            text: 'Fecha',
            headerClasses: 'align-top',
            headerStyle: { width: '20%' },
            filter: textFilter(),
        },
        {
            dataField: '',
            text: 'Opciones',
            formatter: optionsFormatter,
            formatExtraData: {
                showForm,
                showDeleteAlert,
                launchNotification,
            },
            headerClasses: 'align-top',
        }
    ];

    const updateModelWithFormData = (e, fieldName) => {
        console.log(e);
        let name, value;

        if (fieldName !== undefined && fieldName !== '') {
            name = fieldName;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        saveCurrentModel({
            ...currentModel,
            [name]: value
        });
    }

    const handleValidation = () => {
        let errors = {};
        let isFormValid = true;

        if (subject === '' || subject.length > 100) {
            errors["subject"] = 'El campo nombre está vacío o supera los 50 caracteres.';
            isFormValid = false;
        }

        if (description === '' || description.length > 100) {
            errors["description"] = 'El campo nombre está vacío o supera los 50 caracteres.';
            isFormValid = false;
        }

        if (notification_type === 0) {
            errors["notification_type"] = 'El campo nombre está vacío o supera los 50 caracteres.';
            isFormValid = false;
        }

        saveFormErrors(errors);
        return isFormValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation()) {
            const res = await axios.post(`/notifications${(currentModel.id != 0 ? '/' + currentModel.id : '')}`, { _method: currentModel.id === 0 ? 'POST' : 'PUT', ...currentModel });
            if (res && res.data && res.data.status) {
                getTableData();
                saveShowModal(false);
            } else {
                $.alert({
                    title: 'Error',
                    content: 'Ha ocurrido un error al guardar los datos en el servidor.',
                    type: 'red',
                });
            }
        }
    };

    return (
        <>
            <h1>Notificaciones</h1>
            <Button variant='success' className='mb-3' onClick={() => { showForm(0); }}><i className="bi bi-plus-circle"></i></Button>
            <BootstrapTable
                bootstrap4={true}
                columns={columns}
                data={tableData}
                keyField='id'
                filter={filterFactory()}
            />

            <Modal show={showModal} onHide={() => { saveShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${id === 0 ? 'Añadir' : 'Editar'}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id='modelForm' onSubmit={handleSubmit}>
                        <Form.Group controlId='subject-control'>
                            <Form.Label>Título</Form.Label>
                            <Form.Control type="text" value={subject} name='subject' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('subject')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.subject}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='description-control'>
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control type="text" value={description} name='description' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('description')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='notification_type-control'>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control as={'select'} value={notification_type} name='notification_type' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('notification_type')}>
                                <option value={0}>Seleccione una opción</option>
                                <option value={1}>Todos</option>
                                <option value={2}>Usuario</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formErrors.notification_type}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {
                            parseInt(notification_type) === 2 ?
                                <Form.Group controlId='user_id-control'>
                                    <Form.Label>Usuario</Form.Label>
                                    <Select
                                        options={users}
                                        onChange={e => { updateModelWithFormData(e, 'user_id') }}
                                        className={"" + (formErrors['user_id'] !== undefined ? 'is-invalid' : '')}
                                        placeholder='Escribe el usuario'
                                        value={
                                            user_id !== 0 ? users.map(dat => {
                                                if (dat.value === user_id) {
                                                    return dat
                                                }
                                            })
                                                : null
                                        }
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                border: (formErrors['user_id'] !== undefined ? '1px solid red' : '')
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.user_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                : null
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { saveShowModal(false); }}>Cerrar</Button>
                    <Button variant="primary" type='submit' form='modelForm'>Guardar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Notifications;