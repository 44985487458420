import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LeftBar from './components/controls/LeftBar';
import TopBar from './components/controls/TopBar';
import Categories from './components/views/Categories';
import Dashboard from './components/views/Dashboard';
import Inscriptions from './components/views/Inscriptions';
import Login from './components/views/Login';
import Meetings from './components/views/Meetings';
import Notifications from './components/views/Notifications';
import Users from './components/views/Users';
import { AuthContext } from './contexts/AuthContext';

const App = () => {

  const authContext = useContext(AuthContext);
  const { isLoggedIn, saveIsLoggedIn, token, saveToken, saveRoleId, saveUserId, saveUserName } = authContext;
  const location = useLocation();

  useEffect(() => {
    const tokenInLocalStorage = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_ITEM_NAME);
    // console.log('TokenStorage=>', tokenInLocalStorage);
    // console.log('Token=>', token);
    if (tokenInLocalStorage !== null && token === '') {
      saveIsLoggedIn(true);
      saveToken(JSON.parse(tokenInLocalStorage).token);
      saveRoleId(JSON.parse(tokenInLocalStorage).role_id);
      saveUserId(JSON.parse(tokenInLocalStorage).user_id);
      saveUserName(JSON.parse(tokenInLocalStorage).name);
    }

    const axiosCommon = { 'Accept': 'application/json' };
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      axios.defaults.baseURL = 'https://localhost/EventikkAPI/public/api/';
    } else if (window.location.hostname.startsWith('192')) {
      axios.defaults.baseURL = `https://${window.location.hostname}/Eventikk/public/api/`;
    } else {
      axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    }
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers["crossDomain"] = true;
    if (tokenInLocalStorage !== null) {
      axios.defaults.headers.common = { ...axiosCommon, 'Authorization': `Bearer ${JSON.parse(tokenInLocalStorage).token}` };
    }
    else if (token !== '') {
      axios.defaults.headers.common = { ...axiosCommon, 'Authorization': `Bearer ${token}` };
    }
    else {
      axios.defaults.headers.common = { ...axiosCommon, 'Authorization': undefined };
    }
    axios.interceptors.response.use(res => { return res; }, error => {
      if (error.response && error.response.status === 401) {
        saveIsLoggedIn(false);
        saveToken('');
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_ITEM_NAME);
      }
      return error;
    });
  }, [token]);

  if (!isLoggedIn) {
    return (
      <Container>
        <Row>
          <Col>
            {(location.pathname !== '/login') ?
              <Navigate to={'/login'} replace={true} />
              : null
            }
            <Routes>
              <Route index element={<Login />} />
              <Route
                exact
                path="/login"
                element={<Login />}
              />
            </Routes>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <>
        <TopBar />
        <Container fluid>
          <Row>
            <LeftBar />
            <Col md={'9'} lg={'10'}>
              {
                location.pathname === '/login' ?
                  <Navigate to={'/dashboard'} />
                  : null
              }
              <Routes>
                <Route index element={<Dashboard />} />
                <Route
                  exact
                  path="/dashboard"
                  element={<Dashboard />}
                />
                <Route
                  exact
                  path="/meetings"
                  element={<Meetings />}
                />
                <Route
                  exact
                  path="/inscriptions"
                  element={<Inscriptions />}
                />
                <Route
                  exact
                  path="/categories"
                  element={<Categories />}
                />
                <Route
                  exact
                  path="/users"
                  element={<Users />}
                />
                <Route
                  exact
                  path="/notifications"
                  element={<Notifications />}
                />
              </Routes>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default App;