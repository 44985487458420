import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {

    const [dashboardModel, saveDashboardModel] = useState({});
    const { total_users, total_meetings, total_inscriptions, total_inscriptions_paid, doughnut_inscriptions } = dashboardModel;

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const res = await axios.get('/dashboard/getData');
        if (res && res.data && res.data.status) {
            saveDashboardModel(res.data.data);
        }
    };

    return (
        <>
            <h1>Dashboard</h1>
            <Row className='mt-5'>
                <Col lg={8}>
                    <Table bordered striped hover>
                        <thead>
                            <tr>
                                <th>Principales datos {(new Date()).getFullYear()}</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Usuarios registrados</td>
                                <td>{total_users}</td>
                            </tr>
                            <tr>
                                <td>Eventos creados</td>
                                <td>{total_meetings}</td>
                            </tr>
                            <tr>
                                <td>Inscripciones creadas</td>
                                <td>{total_inscriptions}</td>
                            </tr>
                            <tr>
                                <td>Total pagado con las inscripciones</td>
                                <td>{total_inscriptions_paid}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col lg={2}>
                    {
                        doughnut_inscriptions !== undefined && doughnut_inscriptions !== null ?
                            <Doughnut
                                data={doughnut_inscriptions}
                            />
                            : null
                    }
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;