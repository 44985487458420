import React from 'react';
import { Badge, Toast } from 'react-bootstrap';

const AlertToast = ({ show, saveShow, badgeBg, title, body }) => {
    return (
        <Toast
            animation={false}
            show={show}
            onClose={() => { saveShow(false); }}
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                marginTop: '10px',
                marginRight: '10px',
            }}
        >
            <Toast.Header>
                <Badge variant={badgeBg} className='mr-2'>&nbsp;&nbsp;</Badge>
                <strong className="mr-auto">{title ?? 'Información'}</strong>
            </Toast.Header>
            <Toast.Body>{body}</Toast.Body>
        </Toast>
    );
}

export default AlertToast;