import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import AlertToast from '../controls/AlertToast';

const Login = () => {

    const [userData, saveUserData] = useState({ 'userName': '', 'userPassword': '' });
    const [showAlert, saveShowAlert] = useState(false);
    const [typeAlert, saveTypeAlert] = useState('danger');
    const [msgAlert, saveMsgAlert] = useState('');

    const authContext = useContext(AuthContext);
    const { saveToken, saveIsLoggedIn, saveRoleId, saveUserId, saveUserName } = authContext;

    const updateData = (e) => {
        e.preventDefault();
        userData[e.target.name] = e.target.value;
        saveUserData(userData);
    };

    const submitData = async (e) => {
        try {
            e.preventDefault();
            saveTypeAlert('danger');
            const res = await axios.post('/getToken', userData);
            if (res.data.status) {
                saveShowAlert(false);
                saveToken(res.data.data.token);
                saveUserId(res.data.data.user_id);
                saveRoleId(res.data.data.role_id);
                saveUserName(res.data.data.name);
                saveIsLoggedIn(true);
                localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_ITEM_NAME, JSON.stringify(res.data.data));
            } else {
                saveShowAlert(true);
                saveMsgAlert('Error al hacer login. Revise sus credenciales.');
            }
        } catch (e) {
            console.log(e);
            saveShowAlert(true);
            saveMsgAlert('Error al hacer login. Revise sus credenciales.');
        }
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-md-center" style={{ marginTop: '200px' }}>
                <div className="col-sm-12 col-md-6 col-lg-4 login-card">
                    <h1>Eventikk CRM</h1>
                    <Form onSubmit={submitData}>
                        <Form.Group>
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Escribe el usuario"
                                onChange={updateData}
                                name='userName'
                            />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Escribe la contraseña"
                                name='userPassword'
                                onChange={updateData}
                            />
                        </Form.Group>
                        <div className='text-right'>
                            <Button variant="primary" type="submit" className='mt-3'>
                                Login
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            <AlertToast
                show={showAlert}
                saveShow={saveShowAlert}
                badgeBg={typeAlert}
                //title={'Alerta'}
                body={msgAlert}
            />
        </div>
    );
}

export default Login;