import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import $ from 'jquery';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';
import { Buffer } from 'buffer';

const Meetings = () => {

    const defaultObj = {
        'id': 0,
        'name': '',
        'address': '',
        'description': '',
        'max_inscriptions': 0,
        'price_per_user': 0,
        'category_id': 0,
        'start_datetime': '',
        'end_datetime': '',
    };
    const [tableData, saveTableData] = useState([]);
    const [categories, saveCategories] = useState([]);
    const [showModal, saveShowModal] = useState(false);
    const [currentModel, saveCurrentModel] = useState(defaultObj);
    const { id, name, address, category_id, description, price_per_inscription, max_inscriptions, start_datetime, end_datetime, } = currentModel;
    const [formErrors, saveFormErrors] = useState({});
    const [cover, saveCover] = useState(null);

    useEffect(() => {
        getCategories();
        getTableData();
    }, []);

    const getCover = async (id) => {
        saveCover(null);
        if (id !== null && id !== '') {
            const res = await axios.get(`/meetings/showCover/${id}`, { responseType: 'arraybuffer' });
            try {
                saveCover(Buffer.from(res.data, 'binary').toString('base64'));
            } catch (error) {

            }
        }
    }

    const getTableData = async () => {
        const res = await axios.get('/meetings');
        if (res && res.data && res.data.status) {
            saveTableData(res.data.data);
        }
    }

    const getCategories = async () => {
        const res = await axios.get('/categories');
        if (res && res.data && res.data.status) {
            let dataSelect = [];
            res.data.data.map(dat => {
                dataSelect.push({
                    value: dat.id,
                    label: dat.name
                });
            })
            saveCategories(dataSelect);
        }
    }

    const showDeleteAlert = (currentId, dataName) => {
        $.confirm({
            title: `¿Está seguro que desea eliminar el evento ${dataName}?`,
            content: '',
            type: 'red',
            buttons: {
                accept: {
                    text: 'ELIMINAR',
                    btnClass: 'btn-danger',
                    keys: [],
                    action: function () {
                        const deleteModel = () => {
                            axios.post(`/meetings/${currentId}`, { _method: 'DELETE' })
                                .then(res => {
                                    if (res && res.data && res.data.status) {
                                        getTableData();
                                    } else {
                                        $.alert({
                                            title: 'Alerta',
                                            content: 'Ha ocurrido un error ejecutando el borrado.',
                                            type: 'red',
                                        });
                                    }
                                    saveShowModal(false);
                                })
                        }
                        deleteModel()
                    }
                },
                cancel: {
                    text: 'CANCELAR',
                    btnClass: 'btn-default',
                    keys: [],
                    action: function () {

                    }
                }
            }
        });
    }

    const showForm = async (currentId) => {
        saveFormErrors({})
        if (currentId === 0) {
            saveCurrentModel(defaultObj);
            getCover(null);
        } else {
            const getModel = async () => {
                const res = await axios.get(`/meetings/${currentId}`);

                if (res && res.data && res.data.status) {
                    saveCurrentModel(res.data.data);
                    getCover(res.data.data.id);
                }

            }
            await getModel();
        }
        saveShowModal(true);
    }

    const optionsFormatter = (cell, row, rowIndex, { showForm, showDeleteAlert }) => {
        return (
            <div className="btn-group" role="group" aria-label="Options">
                <button className="btn btn-primary text-white" onClick={() => { showForm(row.id); }}><i className="bi bi-pencil-square"></i></button>
                <button className="btn btn-danger" onClick={() => { showDeleteAlert(row.id, row.name); }}><i className="bi bi-x-circle"></i></button>
            </div >
        )
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            headerClasses: 'align-top header-id',
        },
        {
            dataField: 'name',
            text: 'Nombre',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: 'address',
            text: 'Dirección',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: 'category_name',
            text: 'Categoría',
            headerClasses: 'align-top',
            filter: textFilter(),
        },
        {
            dataField: '',
            text: 'Opciones',
            formatter: optionsFormatter,
            formatExtraData: {
                showForm,
                showDeleteAlert,
            },
            headerClasses: 'align-top',
        }
    ];

    const updateModelWithFormData = (e, fieldName) => {
        let name, value;

        if (fieldName !== undefined && fieldName !== '') {
            name = fieldName;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        saveCurrentModel({
            ...currentModel,
            [name]: value
        });
    }

    const handleValidation = () => {
        let errors = {};
        let isFormValid = true;

        if (max_inscriptions === '' || parseInt(max_inscriptions) === NaN) {
            errors["max_inscriptions"] = 'El campo está vacío.';
            isFormValid = false;
        }

        if (start_datetime === '') {
            errors["start_datetime"] = 'El campo está vacío.';
            isFormValid = false;
        }

        if (end_datetime === '') {
            errors["end_datetime"] = 'El campo está vacío.';
            isFormValid = false;
        }

        if (name === '' || name.length > 100) {
            errors["name"] = 'El campo nombre está vacío o supera los 50 caracteres.';
            isFormValid = false;
        }

        if (address === '' || address.length > 200) {
            errors["address"] = 'El campo dirección está vacío o supera los 200 caracteres.';
            isFormValid = false;
        }

        if (description === '' || description.length > 500) {
            errors["description"] = 'El campo descripción está vacío o supera los 500 caracteres.';
            isFormValid = false;
        }

        if (category_id === 0) {
            errors["category_id"] = 'El campo categoría está vacío.';
            isFormValid = false;
        }

        saveFormErrors(errors);
        return isFormValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation()) {
            const _currentModel = { ...currentModel }
            const file = e.target[8].files[0];
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const fileData = new FormData();
            fileData.append('file', file);
            Object.keys(_currentModel).map(k => {
                fileData.append(k, _currentModel[k]);
            });
            fileData.append('_method', (currentModel.id === 0 ? 'POST' : 'PUT'));
            const res = await axios.post(`/meetings${(currentModel.id != 0 ? '/' + currentModel.id : '')}`, fileData, config);
            if (res && res.data && res.data.status) {
                getTableData();
                saveShowModal(false);
            } else {
                $.alert({
                    title: 'Error',
                    content: 'Ha ocurrido un error al guardar los datos en el servidor.',
                    type: 'red',
                });
            }
        }
    };

    return (
        <>
            <h1>Eventos</h1>
            <Button variant='success' className='mb-3' onClick={() => { showForm(0); }}><i className="bi bi-plus-circle"></i></Button>
            <BootstrapTable
                bootstrap4={true}
                columns={columns}
                data={tableData}
                keyField='id'
                filter={filterFactory()}
            />

            <Modal show={showModal} onHide={() => { saveShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${id === 0 ? 'Añadir' : 'Editar'}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        id !== 0 ?
                            <Alert variant='info'>Recuerda que solo se puede cambiar el nombre y la descripción de los eventos existentes.</Alert>
                            : null
                    }
                    <Form id='modelForm' onSubmit={handleSubmit} autoComplete={'off'}>
                        <Form.Group controlId='name-control'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" value={name} name='name' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('name')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="description-control">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control as="textarea" rows={3} value={description} name='description' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('description')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='address-control'>
                            <Form.Label>Dirección</Form.Label>
                            <Form.Control type="text" value={address} name='address' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('address')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.address}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='max_inscriptions-control'>
                            <Form.Label>Máximo número de inscripciones</Form.Label>
                            <Form.Control type="text" value={max_inscriptions} name='max_inscriptions' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('max_inscriptions')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.max_inscriptions}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='price-control'>
                            <Form.Label>Precio (utilizar sistema de separación inglés)</Form.Label>
                            <Form.Control type="number" value={price_per_inscription} name='price_per_inscription' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('price_per_inscription')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.price_per_inscription}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='start_datetime-control'>
                            <Form.Label>Fecha inicio</Form.Label>
                            <Form.Control type="datetime-local" value={start_datetime} name='start_datetime' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('start_datetime')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.start_datetime}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='end_datetime-control'>
                            <Form.Label>Fecha fin</Form.Label>
                            <Form.Control type="datetime-local" value={end_datetime} name='end_datetime' onChange={updateModelWithFormData} isInvalid={Object.keys(formErrors).includes('end_datetime')} />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.end_datetime}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="type-control">
                            <Form.Label>Categoría</Form.Label>
                            <Select
                                options={categories}
                                onChange={e => { updateModelWithFormData(e, 'category_id') }}
                                className={"" + (formErrors['category_id'] !== undefined ? 'is-invalid' : '')}
                                placeholder='Escribe la categoría'
                                value={
                                    category_id !== 0 ? categories.map(dat => {
                                        if (dat.value === category_id) {
                                            return dat
                                        }
                                    })
                                        : null
                                }
                                styles={{
                                    control: base => ({
                                        ...base,
                                        border: (formErrors['category_id'] !== undefined ? '1px solid red' : '')
                                    })
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.category_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Imagen de portada</Form.Label>
                            <Form.File
                                // onChange={updateModelWithFormData}
                                name='localPath'
                                accept="image/png, image/gif, image/jpeg"
                            // value={localPath}
                            />
                            <Form.Text className="text-muted">Si vuelve a subir una imagen, se reemplazará la anterior.</Form.Text>
                        </Form.Group>
                        {
                            cover !== null ?
                                <img src={`data:image/png;base64, ${cover}`} className='mw-100' />
                                : null
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { saveShowModal(false); }}>Cerrar</Button>
                    <Button variant="primary" type='submit' form='modelForm'>Guardar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Meetings;