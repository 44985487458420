import React, { useContext } from 'react';
import { Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const LeftBar = () => {

    const authContext = useContext(AuthContext);
    const { saveIsLoggedIn, saveToken, } = authContext;

    const logOut = async () => {
        saveIsLoggedIn(false);
        saveToken('');
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_ITEM_NAME)
    }

    return (
        <Col md={'3'} lg={'2'} className='px-0'>
            <Nav defaultActiveKey="/dashboard" className="flex-column bg-white p-3" style={{ minHeight: '800px' }}>
                <Link to={'/dashboard'} className='nav-link'>Dashboard</Link>
                <Link to={'/categories'} className='nav-link'>Categorías</Link>
                <Link to={'/meetings'} className='nav-link'>Eventos</Link>
                <Link to={'/inscriptions'} className='nav-link'>Inscripciones</Link>
                {/* <Link to={'/meetings'} className='nav-link'>Reviews</Link>
                <Link to={'/meetings'} className='nav-link'>Comentarios</Link> */}
                <Link to={'/users'} className='nav-link'>Usuarios</Link>
                <Link to={'/notifications'} className='nav-link'>Notificaciones</Link>
                <Link onClick={() => { logOut(); }} className='nav-link'>Cerrar sesión</Link>
            </Nav>
        </Col>
    );
}

export default LeftBar;